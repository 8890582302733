import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject } from 'react';
import ScrollBooster from 'scrollbooster';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassNames from '../../utils/className.utils';
import { wait } from '../../utils/waiters.utils';
import './HorizontalSwiper.scss';

type HorizontalSwiperProps = {
  direction?: 'ltr' | 'rtl',
  innerRef?: MutableRefObject<HTMLDivElement | null>,
}

const HorizontalSwiper: React.FC<HorizontalSwiperProps> = props => {
  const refTrack = useObservableRef();
  const p = props;
  const s = {
    scrollBoosters: [] as unknown[],
    initScrollBooster: action(() => {
      // console.log('init scroll booster');
      const track = refTrack.current;
      if (!track) return;
      s.scrollBoosters.push(new ScrollBooster({
        viewport: track,
        // @ts-ignore
        mode: 'x',
        pointerMode: 'mouse',
        scrollMode: 'native',
        onUpdate: (data: any) => {
          track.scrollLeft = data.position.x;
        }
      }));
      let mousedownX: number, mousedownY: number;
      track.addEventListener('mousedown', e => {
        mousedownX = e.screenX;
        mousedownY = e.screenY;
      })
      const handler = flow(function* (e) {
        const mouseHasMovedSoTreatAsScrolled = Math.abs(e.screenX - mousedownX) > 10 || Math.abs(e.screenY - mousedownY) > 10;
        if (mouseHasMovedSoTreatAsScrolled) {
          yield wait(100);
        }
      });
      track.addEventListener('mouseup', handler, { capture: true });
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isSamsung = navigator.userAgent.match(/SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i);
      if (isIOS || isSamsung) {
        s.scrollBoosters.forEach((b: any) => b.destroy());
      }
    }),
  }
  useOnMount(() => {
    s.initScrollBooster();
  })
  return <Observer children={() => (
    <div className="HorizontalSwiper">
      <div className="HorizontalSwiper-slider-track" ref={refTrack}>
        <div className={joinClassNames('HorizontalSwiper-slider-track__inner', p.direction)} ref={p.innerRef}>
          {p.children}
        </div>
      </div>
    </div>
  )} />
}

export default HorizontalSwiper;